import Vue from "vue";
import { API } from "../../../services/API"

function initialState() {
  return {
    dados: [],
    acao: "C", // E=Edicao, I=Inclusão, C=Consulta
    imovel_edicao: {},  // Imovel Selecionada para Edição
    imovel_selecionado: {}, 
    
    cadastro_imovelAux: {},     //Dados a serem alterados no cadastro/edicao do endereco da obra
    cadastro_cobrancaAux: {}, //Dados a serem alterados no cadastro/edicao do endereco de cobrancao

    currentPage: 1,
    count_dados: 22,
    paginas    : 0,

    // Filtro na Tela de Consulta 
    filtro: {
      cod_imovel: null,
      nome_imovel: null,
      localizacao: null,
      area_construida: null,
      tipo: null,
      area_construida_min: null,
      area_construida_max: null,
      area_terreno_min: null,
      area_terreno_max: null,
      situacao: null,
      empreendimento: null,
    },
    // Abas do container 
    abas: [
      ['mdi-text-box-search-outline', 'Dados', '0'],
      ['mdi-image-multiple-outline', 'Fotos', '0'],
      ['mdi-file-document-outline', 'Documentos', '0'],
      ['mdi-cash-multiple', 'Contas a Pagar', '0'],      
    ],
  }
}

var vm_store_Imovel = new Vue({

  data() {
    return initialState();
  },

  methods: {
    // resetData() {
    //   Object.assign(this.$data, initialState());
    // },

    // Métodos da Obra - Gets / Post / Put / Delete
    // Método - Get All
    async ImoveisGet(p_params) {
      const resposta = await API.get("/imovel", { params: { ...p_params } });
      if (resposta) {
        this.dados = [];
        if (resposta.data.result != 'Não possui dados') {
          this.dados = resposta.data.result.rows
          this.count_dados = resposta.data.result.count;
          this.paginas = Math.ceil(this.count_dados / 20)
          return resposta.data.result
        }
      }
    },
  
    // Método - Get Individual
    // async ImovelGet(p_params) {
    //   const resposta = await API.get(`/imovel`, { params: { ...p_params }});
    //   return resposta.data.result;
    // },
    // // Método - Post 
    // async ImovelPost(p_params) {
    //   const resp = await API.post(`/imoveis`, { params: { ...p_params }});
    //   return resp;
    // },
    // // Método - Put
    // async ImovelPut(p_params) {
    //   const resp = await API.put(`/imoveis/`, { params: { ...p_params }});
    //   return resp;
    // },
    // // Método - Delete
    // async ImovelDelete(p_params) {
    //   const resp = await API.delete(`/imoveis`, { params: { ...p_params }});
    //   return resp;
    // },



    // Imovel Detalhe

    // async ImovelDetalhesGet(p_params) {
    //   const resposta = await API.get("/imovel_detalhes", { params: { ...p_params } });
    //   console.log('resposta', resposta)
    //   if (resposta) {
    //     this.dados = [];
    //     if (resposta.data.result != 'Não possui dados') {
    //       this.dados = resposta.data.result
    //       this.count_dados = resposta.data.result.length;
    //       return resposta.data.result
    //     }
    //   }
    // },

    // async ImovelDetalheGet(p_params) {
    //   const resposta = await API.get(`/imovel_detalhe`, { params: { ...p_params }});
    //   console.log('Get Individual', resposta)
    //   return resposta.data.result;
    // },

    // async ImovelDetalhePost(p_params){
    //   const resposta = await API.post(`/imovel_detalhe`, { params: { ...p_params }});
    //   console.log('Post', resposta)
    //   return resposta.data.result;
    // },

    // async ImovelDetalheDelete(p_params){
    //   if (!p_params.cod_imovel_detalhe) {
    //     console.error('Parâmetro cod_imovel_detalhe está indefinido:', p_params);
    //     return;
    //   }
    
    //   const resposta = await API.delete(`/imovel_detalhe/${p_params.cod_imovel_detalhe}`);
    //   console.log('Delete', resposta);
    //   return resposta.data.result;
    // },
    

    async EmpreendimentoGet(p_params) {
      const resposta = await API.get("empreendimento", {
        params: {
          ...p_params,
        },
      });

      if (resposta) {
        this.dados = [];
        if (resposta.data.result != "Não possui dados") {
          if (p_params && p_params.cod_empreendimento) {
            // console.log("this.usuario_selecionado--", resposta);
            //this.usuario_selecionado = resposta.data.result;
            return resposta.data.result;
          } else {
            this.dados = resposta.data.result;
            this.paginas = resposta.data.result;
          }
        }
      }
    },

  },
  
  // watch: {
  //   // docDocumentos()           { this.atualiza_abas() },
  //   // docContrato()             { this.atualiza_abas() },
  //   // docTransferencia()        { this.atualiza_abas() },
  //   // docRenogociacao()         { this.atualiza_abas() },
  //   // docRescisao()             { this.atualiza_abas() },
  //   // docAutorizacaoEscritura() { this.atualiza_abas() },
  //   // docEscritura()            { this.atualiza_abas() },
  //   // docMensagens()            { this.atualiza_abas() }
  // },
})

export default vm_store_Imovel
