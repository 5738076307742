<template>
  <div id="ImovelCadastro" >
    <!-- Cabeçalho - Titulo / Botão Editar-->
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-2"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1 ma-0"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Dados Básicos
      </p>
      <v-btn
        v-if="acao == 'C'"
        @click="Editar()"
        class="btn mr-4 pr-6"
        style="background-color: #dce1eb"
        elevation="0"
      >
        <v-icon class="btn-icon mr-1" color="light-blue accent-2 "
          >mdi-view-headline</v-icon
        >
        <span
          class="caption font-weight-medium"
          v-bind:style="{ color: COR_SUBTITULO }"
          >Editar</span
        >
      </v-btn>
    </v-toolbar-title>

    <v-dialog
      v-model="dados.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
    </v-dialog>

    <!-- Formulario Cadastro/Edição ----------------------------------------------------------->
    <v-form v-model="valid" id="form" ref="form" class="mx-0 mt-3">
      <!-- Dados Básicos -->
      <v-container class="mt-2 d-flex flex-column">
        <!-- 1ª Linha - Avatar / Nome / Área construida / Área terreno -->
        <v-row no-gutter class="pt-0 mb-n14">
          <!-- Avatar da Imóvel -->
          <v-col sm="2" class="pt-2 px-2 justify-center">
            <div class="d-none d-sm-flex justify-center">
              <v-avatar
                rounded
                size="100px"
                class="grey lighten-2"
              >
                <span v-if="!dados.imagem_capa_caminho">
                    {{ nomeIniciais(dados.nome_imovel) }}
                </span>
                <v-img
                  v-if="dados.imagem_capa_caminho"
                  :src="baseURL + dados.imagem_capa_caminho"
                  alt="avatar"
                />
              </v-avatar>
            </div>
          </v-col>

          <!-- Nome do Imovel -->
          <v-col md="6">
            <v-text-field
              v-model="dados.nome_imovel"
              class="mb-0"
              :readonly="this.acao == 'C'"
              loader-height="1"
              label="Nome do Imóvel"
              placeholder="Nome do Imóvel"
              background-color="#FFF"
              maxlength="100"
              light
              filled
              outlined
              dense
              :rules="nomeRules"
            ></v-text-field>
          </v-col>

          <!-- Metragem -->
          <v-col md="2">
            <VuetifyMoney
              v-model="dados.area_metro"
              :clearable="this.acao != 'C'"
              class="mb-0"
              light
              loader-height="1"
              background-color="#FFF"
              label="Metragem (m²)"
              placeholder="Metragem (m²)"
              :readonly="this.acao == 'C'"
              filled
              outlined
              maxlength="15"
              dense
              @input="calcularPreco"
              :rules="metragemRules">
            </VuetifyMoney>
          </v-col>

          <!-- Área Construida -->
          <v-col md="2">
            <VuetifyMoney
              v-model="dados.area_construida"
              class="mb-0"
              :readonly="this.acao == 'C'"
              :clearable="this.acao != 'C'"
              loader-height="1"
              label="Área Construída (m²)"
              placeholder="Área Construída (m²)"
              background-color="#FFF"
              maxlength="15"
              light
              filled
              outlined
              dense>
            </VuetifyMoney>
          </v-col>
        </v-row>

        <!-- 2ª Linha - Nº Matricula / Data Matricula / Cartorio -->
        <v-row no-gutter class="pt-0 mb-n10">
          <v-col cols="2"></v-col>
          <!-- Nº Matricula -->
          <v-col md="2" class="pt-0">
            <v-text-field
              v-model="dados.matricula"
              class="mb-0"
              :readonly="this.acao == 'C'"
              loader-height="1"
              label="Nº Matricula"
              placeholder="Nº Matricula"
              background-color="#FFF"
              maxlength="15"
              light
              filled
              outlined
              dense
            ></v-text-field>
          </v-col>

          <!-- Data Matricula -->
          <v-col md="2" class="pt-0">
            <DatePicker
              v-model="dados.dt_matricula"
              class="mb-0"
              :readonly="this.acao == 'C'"
              loader-height="1"
              background-color="#fff"
              label="Data Matricula"
              placeholder="Data Matricula"
              light
              maxlength="80"
              filled
              outlined
              dense
            ></DatePicker>
          </v-col>
          <!-- Situação -->
          <v-col md="3" class="pt-0">
            <v-autocomplete
              :items="array_situacoes"
              item-text="situacao_nome"
              item-value="cod_situacao"
              v-model="dados.cod_situacao"
              :readonly="this.acao == 'C'"
              class="mb-0"
              loader-height="1"
              background-color="#FFF"
              label="Situação"
              light
              filled
              outlined
              dense
              :append-icon="this.acao != 'C' ? 'mdi-menu-down' : ''"
              :rules="situacaoRules">
            >
            </v-autocomplete>
          </v-col>

          <!-- Complemento Situação -->
          <v-col md="3" class="pt-0">
            <v-text-field
              v-model="dados.situacao_complemento"
              class="mb-0"
              :readonly="this.acao == 'C'"
              loader-height="1"
              label="Complemento da Situação"
              placeholder="Complemento da Situação"
              background-color="#FFF"
              maxlength="20"
              light
              filled
              outlined
              dense
            ></v-text-field>
          </v-col>

          <!-- Cartorio
          <v-col md="5" class="pt-0">
            <v-text-field
              v-model="dados.cartorio"
              label="Cartório"
              :readonly="this.acao == 'C'"
              placeholder="Cartório"
              class="mb-0"
              filled
              outlined
              dense
              background-color="#fff  "
              item-text="name"
            >
            </v-text-field>
          </v-col> -->

        </v-row>

        <!-- 3ª Linha - Tipo / Situação / Proprietário  -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Tipo -->
          <v-col md="3">
             <v-autocomplete
              label="Tipo"
              auto-select-first
              filled
              outlined
              dense
              background-color="map-deep-merge white"
              v-model="dados.cod_imoveltipo"
              :items="array_tipo_imovel"
              item-text="nome"
              item-value="cod_imoveltipo"
              :readonly="this.acao == 'C'"
              :append-icon="this.acao != 'C' ? 'mdi-menu-down' : ''"
              :rules="tipoRules"
            ></v-autocomplete>
          </v-col>
          
          <!-- Fase -->
          <v-col md="3">
            <v-text-field
              v-model="dados.fase"
              label="Fase"
              :readonly="this.acao == 'C'"
              placeholder="Fase"
              class="mb-0"
              filled
              maxlength="20"
              outlined
              dense
              background-color="#fff  "
            >
            </v-text-field>
          </v-col>

          <!-- Preço -->
          <v-col md="3">
            <VuetifyMoney
              v-model="dados.preco_metro"
              class="mb-0"
              light
              loader-height="1"
              background-color="#FFF"
              label="Preço (m²)"
              placeholder="Preço (m²)"
              outlined
              maxlength="15"
              filled
              :clearable="this.acao != 'C'"
              :readonly="this.acao == 'C'"
              dense
              @input="calcularPreco"
              :rules=precoRules>
            </VuetifyMoney>
          </v-col>

          <v-col col="3">
            <VuetifyMoney
              v-model="dados.preco_total"
              class="mb-0"
              filled
              dense
              maxlength="15"
              outlined
              :clearable="this.acao != 'C'"
              background-color="#FFF"
              placeholder="Preço Total (valor)"
              label="Preço Total (valor)"
              @input="calcularPrecoMetro"
              :readonly="situacao_aux === 'Vendido' || this.acao == 'C'"
              :rules=precoRules>
            </VuetifyMoney>
          </v-col>
        </v-row>
        
        <hr style="width: 100%; opacity: 0" class="my-2">
        <!-- Dados complementares -->
        <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
        >
          Dados complementares
        </p>  

        <!-- Linha 4 - CEP -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- CEP -->
          <v-col md="2">
            <v-text-field
              v-model="dados.cep"
              class="mb-0"
              light
              background-color="#FFF"
              label="Cep"
              placeholder="xxxxx-xxx"
              maxlength="9"
              filled
              outlined
              required
              dense
              v-mask="MASK_CEP"
              :readonly="this.acao == 'C'"
              @keyup.enter="buscaEndereco(dados.cep)"
              :rules="cepRules"
            >
              <template v-if="this.acao != 'C'" v-slot:append>
                <v-icon
                  class="mb-0"
                  color="#afafaf"
                  @click="buscaEndereco(dados.cep)"
                  >mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>

          <!-- Logradouro -->
          <v-col md="4">
            <v-text-field
              v-model="dados.logradouro"
              class="mb-0"
              light
              background-color="#FFF"
              label="Endereço"
              placeholder="Endereço"
              filled
              outlined
              maxlength="80"
              dense
              :readonly="this.acao == 'C'"
              :rules="enderecoRules"
            />
          </v-col>

          <!-- Número -->
          <v-col md="2">
            <v-text-field
              v-model="dados.numero"
              class="mb-0"
              light
              background-color="#FFF"
              label="Número"
              placeholder="Número"
              filled
              maxlength="20"
              outlined
              required
              dense
              :readonly="this.acao == 'C'"
            />
          </v-col>

          <!-- Bairro -->
          <v-col md="4">
            <v-text-field
              v-model="dados.bairro"
              class="mb-0"
              light
              background-color="#FFF"
              label="Bairro"
              maxlength="40"
              placeholder="Bairro"
              filled
              outlined
              required
              dense
              :readonly="this.acao == 'C'"
            />
          </v-col>
        </v-row>

        <!-- Linha 5 - Municipio-->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Municipio -->
          <v-col md="4">
            <v-autocomplete
              v-model="dados.cod_municipio"
              :loading="loading"
              :search-input.sync="search"
              :items="arrayMunicipios"
              :clearable="this.acao != 'C'"
              no-filter
              hide-selected
              class="mb-0"
              label="Município"
              item-text="nome_exibicao"
              item-value="codigo"
              filled
              outlined
              dense
              background-color="#FFF"
              required
              :readonly="this.acao == 'C'"
            />
          </v-col>

          <!-- Complemento -->
          <v-col md="4">
            <v-text-field
              v-model="dados.complemento"
              class="mb-0"
              light
              background-color="#FFF"
              label="Complemento"
              placeholder="Complemento"
              filled
              maxlength="40"
              outlined
              required
              dense
              :readonly="this.acao == 'C'"
            />
          </v-col>
          
           <!-- Quadra -->
          <v-col md="2">
            <v-text-field
              v-model="dados.quadra"
              class="mb-0"
              light
              background-color="#FFF"
              label="Quadra"
              placeholder="Quadra"
              filled
              maxlength="10"
              outlined 
              required
              dense
              :readonly="this.acao == 'C'"
            />
          </v-col>

          <!-- Lote -->
          <v-col md="2">
            <v-text-field
              v-model="dados.lote"
              class="mb-0"
              light
              background-color="#FFF"
              label="Lote"
              placeholder="Lote"
              filled
              outlined
              maxlength="10"
              required
              dense
              :readonly="this.acao == 'C'"
            />
          </v-col>
        </v-row>

        <!-- Linha 6 - Posição / Localizacao / Utilização / Estado do Imovel, Desmembramento -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!--  Posição Quadra -->
          <v-col md="4">
            <v-select
              label="Posição quadra"
              filled
              class="mb-0"
              outlined
              required
              dense
              light
              background-color="#FFF"
              v-model="dados.posicao"
              :readonly="this.acao == 'C'"
              :items="array_posicao_quadra">
            </v-select>
          </v-col>

          <!-- Localização -->
          <v-col md="4">
            <v-select
              label="Localização"
              v-model="dados.localizacao"
              filled
              class="mb-0"
              outlined
              required
              dense
              light
              :readonly="this.acao == 'C'"
              background-color="#FFF"
              :items="array_localizacao">
            </v-select>
          </v-col>

          <!-- Caucionado -->
          <v-col md="4">
            <v-select
              label="Caucionado"
              filled
              class="mb-0"
              outlined
              required
              dense
              light
              background-color="#FFF"
              v-model="dados.caucionado"
              :disabled="situacao_aux === 'Vendido' || this.acao == 'C'"
              :items="array_sim_nao">
            </v-select>
          </v-col>
        </v-row>

        <!-- Linha Posição Quadra, Numero Frente, Caucionado, Testada, Lado -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Utilização -->
          <v-col md="4">
             <v-select
              label="Utilização"
              filled
              class="mb-0"
              outlined
              required
              dense
              light
              :readonly="this.acao == 'C'"
              background-color="#FFF"
              v-model="dados.utilizacao"
              :items="array_utilizacao">
            </v-select>
          </v-col>
          
          <!-- Estado do Imovel -->
          <v-col md="4">
            <v-select
              label="Estado do imóvel"
              filled
              class="mb-0"
              outlined
              required
              dense
              light
              :readonly="this.acao == 'C'"
              background-color="#FFF"
              v-model="dados.cod_imovelestado"
              :items="array_estado_imovel"
              item-text = "nome"
              item-value = "cod_imovelestado">
            </v-select>
          </v-col>

          <!-- Permite desmembr. -->
          <v-col md="4">
            <v-select
              label="Desmembramento"
              filled
              class="mb-0"
              outlined
              required
              dense
              light
              background-color="#FFF"
              v-model="dados.permite_desmembramento"
              :disabled="situacao_aux === 'Vendido' || this.acao == 'C'" 
              :items="array_sim_nao">
            </v-select>
          </v-col>          
        </v-row>

        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Número Frentes -->
          <v-col md="2">
            <v-text-field
              v-model="dados.frente_quant"
              class="mb-0"
              light
              background-color="#FFF"
              label="Número frente(s)"
              placeholder="Número frente(s)"
              filled
              outlined
              required
              dense
              :readonly="this.acao == 'C'"
            />
          </v-col>

          <!-- Frentes Testada -->
          <v-col md="2">
            <VuetifyMoney
              v-model="dados.frente_testada"
              class="mb-0 "
              light
              outlined
              maxlength="10"
              required
              background-color="#FFF"
              label="Testada (ml)"
              placeholder="Testada (ml)"
              filled
              dense
              :readonly="this.acao == 'C'"
              :clearable="this.acao != 'C'"
              >
            </VuetifyMoney>
          </v-col>

          <!-- Lado -->
          <v-col md="2">
            <VuetifyMoney
              v-model="dados.lado"
              class="mb-0 "
              light
              outlined
              required
              background-color="#FFF"
              label="Lado (ml)"
              maxlength="10"
              placeholder="Lado (ml)"
              filled
              :readonly="this.acao == 'C'"
              :clearable="this.acao != 'C'"
              dense>
            </VuetifyMoney>
          </v-col>

          <!-- Fundo -->
          <v-col md="2">
            <VuetifyMoney
              v-model="dados.fundo"
              class="mb-0 "
              light
              outlined
              required
              maxlength="10"
              background-color="#FFF"
              label="Fundo (ml)"
              placeholder="Fundo (ml)"
              filled
              :readonly="this.acao == 'C'"
              :clearable="this.acao != 'C'"
              dense>
            </VuetifyMoney>
          </v-col>

          <!-- Cadastro Prefeitura -->
          <v-col md="4">
            <v-text-field
              v-model="dados.cadastro_prefeitura"
              class="mb-0 "
              light
              outlined
              maxlength="30"
              required
              background-color="#FFF"
              label="Cadastro prefeitura"
              placeholder="Cadastro prefeitura"
              filled
              :readonly="this.acao == 'C'"
              :clearable="this.acao != 'C'"
              dense>
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Confrontacoes -->
        <v-row no-gutter class="pt-0 mb-n10">
           <v-col md="12" >
              <v-textarea
                v-model="dados.confrontacoes"
                rows="4"
                auto-grow 
                counter="2000"
                maxlength="2000"
                outlined
                class="mb-0"
                background-color="#FFF"
                label="Confrontações"
                placeholder="Confrontações"
                filled
                :readonly="this.acao == 'C'"
                dense>
              </v-textarea>
            </v-col>
        </v-row>
        <hr class="mt-7" style="opacity: 0.2">
      </v-container>

      <v-container class="d-flex flex-column">
        <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
        >
          Dados Contábeis
        </p>  

        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Código Contábeis -->
          <v-col md="3">
            <v-text-field
              v-model="dados.codigo_contabil"
              class="mb-0 "
              light
              loader-height="1"
              background-color="#FFF"
              outlined
              label="Código contábil"
              placeholder="Código contábil"
              filled
              dense>
            </v-text-field>
          </v-col>

          <!-- Numero IPTU -->
          <v-col md="3">
            <v-text-field
              v-model="dados.controle_iptu"
              class="mb-0 "
              light
              loader-height="1"
              background-color="#FFF"
              outlined
              label="Número controle iptu"
              placeholder="Número controle iptu"
              filled
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <!-- Campos - Características do Imóvel / Quantidades -->
      <v-container class="d-flex flex-column">
        <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
        >
          Características do Imóvel
        </p>  

        <!-- Linha 6 -->
        <v-row no-gutter class="pt-0 mb-n10">
            <!-- Quartos -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.quarto"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Quartos"
                placeholder="Quartos"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Suites -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.suite"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Suites"
                placeholder="Suites"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Banheiros -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.banheiro"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Banheiros"
                placeholder="Banheiros"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Lavabos -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.lavabo"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Lavabos"
                placeholder="Lavabos"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            
            <!--Salas -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.sala"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Salas"
                placeholder="Salas"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Cozinhas -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.cozinha"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Cozinhas"
                placeholder="Cozinhas"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Piscinas --> 
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.piscina"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Piscinas"
                placeholder="Piscinas"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>
    
            <!-- Andares -->
            <v-col md="2" >
              <v-text-field
                v-model="dados.imovel_detalhe.andar"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Andares"
                placeholder="Andares"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>


            <!-- Escritorios -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.escritorio"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Escritórios"
                placeholder="Escritórios"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Elevadores -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.elevador"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Elevadores"
                placeholder="Elevadores"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>
      
            <!-- Vagas Cobertas -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.vaga_coberta"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Vagas Cobertas"
                placeholder="Vagas Cobertas"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Vaga sem cobertura -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.vaga_sem_cobertura"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Vagas Sem Cobertura"
                placeholder="Vagas Sem Cobertura"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Ar-condicionado -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.ar_condicionado"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Ar Condicionado"
                placeholder="Ar Condicionado"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Docas -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.docas"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Docas"
                placeholder="Docas"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <!-- Lavanderia -->
            <v-col md="2">
              <v-text-field
                v-model="dados.imovel_detalhe.lavanderia"
                :readonly="this.acao == 'C'"
                class="mb-0"
                background-color="#FFF"
                label="Lavanderia"
                placeholder="Lavanderia"
                type="number"
                hide-details
                required
                filled
                outlined
                dense
              >
              </v-text-field>
            </v-col>
        </v-row>
      </v-container>

      <!-- Caracteristicas Especiais -->
      <v-container class="mt-3 d-flex flex-column">
        <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Características Especiais
      </p>  
        <v-row no-gutter class="pt-0 mb-5">
          <!-- Academia -->
          <v-col md="2" >
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.academia"
            >
              <template v-slot:label>
                <div class="custom-label">Academia</div>
              </template>
            </v-checkbox>
          </v-col>

           <!-- Acessibilidade Deficiente -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.acess_deficiente"
            >
              <template v-slot:label>
                <div class="custom-label">Acess Defic.</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Aclive -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.aclive"
            >
              <template v-slot:label>
                <div class="custom-label">Aclive</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Água Nascente -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.nascente"
            >
              <template v-slot:label>
                <div class="custom-label">Água Nascente</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Área de Lazer -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.area_lazer"
            >
              <template v-slot:label>
                <div class="custom-label">Área de Lazer</div>
              </template>
            </v-checkbox>
          </v-col>


           <!-- Área Rural -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.area_rural"
            >
              <template v-slot:label>
                <div class="custom-label">Área Rural</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Carpete -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.carpete"
            >
              <template v-slot:label>
                <div class="custom-label">Carpete</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Casa Caseiro -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.casa_caseiro"
            >
              <template v-slot:label>
                <div class="custom-label">Casa Caseiro</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Câmara frigorifica -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.camara_frigorifica"
            >
              <template v-slot:label>
                <div class="custom-label">Câmara Frigor.</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Churrasqueira -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.churrasqueira"
            >
              <template v-slot:label>
                <div class="custom-label">Churrasqueira</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Closet -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.closet"
            >
              <template v-slot:label>
                <div class="custom-label">Closet</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Condominio -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.condominio"
            >
              <template v-slot:label>
                <div class="custom-label">Condomínio</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Cobertura -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.cobertura"
            >
              <template v-slot:label>
                <div class="custom-label">Cobertura</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Curral -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.curral"
            >
              <template v-slot:label>
                <div class="custom-label">Curral</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Declive -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.declive"
            >
              <template v-slot:label>
                <div class="custom-label">Declive</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Despensa -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.despensa"
            >
              <template v-slot:label>
                <div class="custom-label">Despensa</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Duplex -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.duplex"
            >
              <template v-slot:label>
                <div class="custom-label">Duplex</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Edícula -->
          <v-col md="2">
            <v-checkbox 
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.edicula"
            >
              <template v-slot:label>
                <div class="custom-label">Edícula</div>
              </template>
            </v-checkbox> 
          </v-col>

          <!-- Energia Eletrica -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.energia_eletrica"
            >
              <template v-slot:label>
                <div class="custom-label">Energia Elet.</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Esquina -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.esquina"
            >
              <template v-slot:label>
                <div class="custom-label">Esquina</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Estúdio -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.estudio"
            >
              <template v-slot:label>
                <div class="custom-label">Estúdio</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Flat -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.flat"
            >
              <template v-slot:label>
                <div class="custom-label">Flat</div>
              </template>
            </v-checkbox>
          </v-col>

           <!-- Galpão -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.galpao"
            >
              <template v-slot:label>
                <div class="custom-label">Galpão</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Horta -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.horta"
            >
              <template v-slot:label>
                <div class="custom-label">Horta</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Irrigação -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.irrigacao"
            >
              <template v-slot:label>
                <div class="custom-label">Irrigação</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Isolada -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.isolada"
            >
              <template v-slot:label>
                <div class="custom-label">Isolada</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Isolamento Acustico -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.isolamento_acustico"
            >
              <template v-slot:label>
                <div class="custom-label">Isol. Acústico</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Isolamento Termico -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.isolamento_termico"
            >
              <template v-slot:label>
                <div class="custom-label">Isol. Térmico</div>
              </template>
            </v-checkbox>
          </v-col>

           <!-- Jardim -->
          <v-col md="2">
            <v-checkbox 
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.jardim"
            >
              <template v-slot:label>
                <div class="custom-label">Jardim</div>
              </template>
            </v-checkbox> 
          </v-col>

          <!-- Lareira -->
          <v-col md="2">
            <v-checkbox 
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.lareira"
            >
              <template v-slot:label>
                <div class="custom-label">Lareira</div>
              </template>
            </v-checkbox> 
          </v-col>

          <!-- Loft -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.loft"
            >
              <template v-slot:label>
                <div class="custom-label">Loft</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Mezanino -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.mezanino"
            >
              <template v-slot:label>
                <div class="custom-label">Mezanino</div>
              </template>
            </v-checkbox>
          </v-col>

           <!-- Murado -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.murado"
            >
              <template v-slot:label>
                <div class="custom-label">Murado</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Painel Solar -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.painel_solar"
            >
              <template v-slot:label>
                <div class="custom-label">Painel Solar</div>
              </template>
            </v-checkbox>
          </v-col>

           <!-- Paletização -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.paletizacao"
            >
              <template v-slot:label>
                <div class="custom-label">Paletização</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Pasto -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.pasto"
            >
              <template v-slot:label>
                <div class="custom-label">Pasto</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Pátio Externo -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.patio_externo"
            >
              <template v-slot:label>
                <div class="custom-label">Patio Externo</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Pavimentação -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.pavimentacao"
            >
              <template v-slot:label>
                <div class="custom-label">Pavimentação</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Piso Cerâmica -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_ceramica"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Cerâmica</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Piso Flutuante -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_flutuante"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Flutuante</div> 
              </template>
            </v-checkbox>
          </v-col>

          <!-- Piso Granito -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_granito"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Granito</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Piso Industrial -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_industrial"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Industrial</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Piso Laminado -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_laminado"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Laminado</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Piso Madeira -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_madeira"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Madeira</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Piso Mármore -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_marmore"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Marmore</div>
              </template>
            </v-checkbox>
          </v-col>

           <!-- Piso Porcelana -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_porcelana"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Porcelana</div>
              </template>
            </v-checkbox>
          </v-col>


          <!-- Piso Vinílico -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.piso_vinilico"
            >
              <template v-slot:label>
                <div class="custom-label">Piso Vinílico</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Playground -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.playground"
            >
              <template v-slot:label>
                <div class="custom-label">Playground</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Portão Eletrônico -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.portao_eletronico"
            >
              <template v-slot:label>
                <div class="custom-label">Portão Eletro.</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Portaria -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.portaria"
            >
              <template v-slot:label>
                <div class="custom-label">Portaria</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Pomar -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.pomar"
            >
              <template v-slot:label>
                <div class="custom-label">Pomar</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Quadra Esportiva -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.quadra_esportiva"
            >
              <template v-slot:label>
                <div class="custom-label">Quadra Esp.</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Quintal -->
          <v-col md="2">
            <v-checkbox 
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.quintal"
            >
              <template v-slot:label>
                <div class="custom-label">Quintal</div>
              </template>
            </v-checkbox> 
          </v-col>

          <!-- Recepção	 -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.recepcao"
            >
              <template v-slot:label>
                <div class="custom-label">Recepção</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Rede Água -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.rede_agua"
            >
              <template v-slot:label>
                <div class="custom-label">Rede Água</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Rede Esgoto -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.rede_esgoto"
            >
              <template v-slot:label>
                <div class="custom-label">Rede Esgoto</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Represa -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.represa"
            >
              <template v-slot:label>
                <div class="custom-label">Represa</div>
              </template>
            </v-checkbox>
          </v-col>


          <!-- Sacada -->
          <v-col md="2">
            <v-checkbox 
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.sacada"
            >
              <template v-slot:label>
                <div class="custom-label">Sacada</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Sala de Reunião -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.sala_reuniao"
            >
              <template v-slot:label>
                <div class="custom-label">Sala Reunião</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Salão de Festas -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.sala_festa"
            >
              <template v-slot:label>
                <div class="custom-label">Salão Festas</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Salão de Jogos -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.sala_jogos"
            >
              <template v-slot:label>
                <div class="custom-label">Salão de Jogos</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Sauna -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.sauna"
            >
              <template v-slot:label>
                <div class="custom-label">Sauna</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Serviço de Limpeza -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.serv_limpeza"
            >
              <template v-slot:label>
                <div class="custom-label">Serv. Limpeza</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Sistema de Segurança -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.sistema_seguranca"
            >
              <template v-slot:label>
                <div class="custom-label">Sist. Segurança</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Sistema de incêndio -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.sistema_incendio"
            >
              <template v-slot:label>
                <div class="custom-label">Sist. Incêndio</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Sobrado -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.sobrado"
            >
              <template v-slot:label>
                <div class="custom-label">Sobrado</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Triplex -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.triplex"
            >
              <template v-slot:label>
                <div class="custom-label">Triplex</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Varanda -->
          <v-col md="2">
            <v-checkbox 
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.varanda"
            >
              <template v-slot:label>
                <div class="custom-label">Varanda</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Vila -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.vila"
            >
              <template v-slot:label>
                <div class="custom-label">Vila</div>
              </template>
            </v-checkbox>
          </v-col>

           <!-- Vitrine -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.vitrine"
            >
              <template v-slot:label>
                <div class="custom-label">Vitrine</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Zeladoria -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            :true-value="'Sim'"
            :false-value="null"
            color="primary"
            v-model="dados.imovel_detalhe.zeladoria"
            >
              <template v-slot:label>
                <div class="custom-label">Zeladoria</div>
              </template>
            </v-checkbox>
          </v-col>

          <!-- Zona Industrial -->
          <v-col md="2">
            <v-checkbox
            hide-details
            :readonly="this.acao == 'C'"
            :ripple="false"
            color="primary"
            v-model="dados.imovel_detalhe.zona_industrial"
            :true-value="'Sim'"
            :false-value="null"
            >
              <template v-slot:label>
                <div class="custom-label">Zona Industrial</div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>


      

      <!-- Rodapé - Botões Salvar / Cancelar -->
      <v-footer
        v-if="acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center  "
        fixed
        style="margin-bottom: 120px; position: static"
      >
        <v-btn
          v-if="acao == 'E'"
          @click="cancelar()"
          cer
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>
        <router-link
          v-else-if="acao == 'I'"
          :to="{ name: 'ImovelConsulta' }"
          class="nav-link"
          aria-current="page"
        >
          <v-btn
            cer
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4 caption font-weight-medium"
            color="primary"
            text
          >
            Cancelar
          </v-btn>
        </router-link>

        <v-btn
          :loading="loading"
          :disabled="!valid"
          @click="CadastraAtualizaImovel()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-form>

    <!-- Modal de seleção de Cliente -->
    <v-container v-if="dialog_seleciona_cliente">
      <v-dialog
        v-model="dialog_seleciona_cliente"
        transition="dialog-bottom-transition"
        max-width="440"
        max-height="200"
      >
        <v-card>
          <ModalSelecaoPessoa
            :ls_Selecionar="true"
            @selecionaPessoa="selecionaCliente" />
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_Empreendimento from "../../Empreendimentos/store_Empreendimento";
import { COR_PRINCIPAL, COR_SUBTITULO, MASK_CEP } from "../../../services/constantes";
import { API, baseURL } from "../../../services/API";
import store_site from "../../../store/store_site";
import Vue from "vue";
import axios from "axios";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue"; 
import {
  nomeIniciais,
} from "../../../services/funcoes"; 

export default {
  name: "ImovelCadastro",

  props: {
    situacao_aux : {
    type : String
    },
    array_unidades_detalhes : {
      type : Object
    },
    acao : {
      type : String
    }
  },

  components: {
    VuetifyMoney,
    DatePicker,
  },

  data() {
    return {
      store_Empreendimento     : store_Empreendimento,
      
      store_site               : store_site,
      COR_PRINCIPAL            : COR_PRINCIPAL,
      COR_SUBTITULO            : COR_SUBTITULO,
      MASK_CEP                 : MASK_CEP,
      nomeIniciais             : nomeIniciais,
      baseURL                  : baseURL,
      dialog_crop              : false,
      dialog_seleciona_cliente : false,
      loading                  : false,
      valid                    : true,
      form                     : false,
      search                   : null,
      imovel_edicaoAux         : null,
      array_tipo_imovel        : [],
      array_situacoes          : [],
      unidades                 : {},
      array_quadras            : [],
      array_proprietarios      : [],
      dados                    : {},
      arrayMunicipios   : [],

      array_sim_nao: [
        "SIM", "NÃO",
      ],
      array_localizacao: [
        "URBANO", "RURAL"
      ],
      array_utilizacao: [
        "COMERCIAL", "INDUSTRIAL", "INSTITUCIONAL", "MISTO", "PUBLICO", "RESIDENCIAL"
      ],
      array_posicao_quadra: [
        "MEIO DE QUADRA", "ESQUINA"
      ],
      array_estado_imovel: [
        { nome: "EM CONSTRUCAO",  cod_imovelestado: 1 },
        { nome: "MURO DE ARRIMO", cod_imovelestado: 2 },
        { nome: "LIMPO",          cod_imovelestado: 3 },
      ],
      nomeRules: [
        (value) => !!value || "O nome é obrigatório",
      ],
      metragemRules: [
        (value) => !!value || "A metragem é obrigatória",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      areaConstruidaRules: [
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      situacaoRules: [
        (value) => !!value || "A situação é obrigatória",
      ],
      tipoRules: [
        (value) => !!value || "O tipo é obrigatório",
      ],
      precoRules: [
        (value) => !!value || "O preço é obrigatório",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0"
      ],

      // Regras de Dados Complementares
      cepRules: [
        (value) => !!value || "O CEP é obrigatório",
        (value) =>
          (value && value.length <= 9) ||
          "O CEP deve ter menos do que 8 caracteres",
      ],
      enderecoRules: [
        (value) => !!value || "O endereço é obrigatório",
      ],
      localizacaoRules: [
        (value) => !!value || "A localização é obrigatória",
      ],
      utilizacaoRules: [
        (value) => !!value || "A utilização é obrigatória",
      ],
      numeroQuadraRules: [
        (value) => !!value || "O número da quadra é obrigatório",
      ],
      loteRules: [
        (value) => !!value || "O lote é obrigatório",
        (value) => /^\d+$/.test(value) || 'O lote não é válido',
        (value) =>
          (value && String(value).length <= 10) ||
          "O lote deve ter menos do que 10 caracteres",
      ],

      situacaocomplementoRules: [
        (value) =>
        (!value || value.length <= 20) ||
          "Deve ter no máximo 20 caracteres" + value && value.length,
      ],
      estadoImovelRules: [
        (value) => !!value || "O estado do imóvel é obrigatório",
      ],
      permiteDesmembramentoRules: [
        (value) => !!value || "Permite desmembramento é obrigatório",
      ],
      posicaoQuadraRules: [
        (value) => !!value || "A posição da quadra é obrigatória",
      ],
      numeroFrenteRules: [
        (value) => !!value || "Número frente(s) é obrigatório",
        (value) => /^\d+$/.test(value) || 'Número frente(s) não é válido',
        (value) =>
          (value && value <= 9999999999) ||
          "Número frente(s) deve ter menos do que 10 caracteres",
      ],
      testadaRules: [
        (value) => !!value || "Testada (ml) é obrigatória",
        (value) =>
          (value && value.length <= 10) ||
          "Testada (ml) deve ter menos do que 8 caracteres",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      ladoRules: [
        (value) => !!value || "O lado (ml) é obrigatório",
        (value) =>
          (value && value.length <= 10) ||
          "O lado (ml) deve ter menos do que 8 caracteres",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      fundoRules: [
        (value) => !!value || "O fundo (ml) é obrigatório",
        (value) =>
          (value && value.length <= 10) ||
          "O fundo (ml) deve ter menos do que 8 caracteres",
        (value) =>
          ( parseFloat(value.replace(",", ".")) > 0) ||
          "O valor deve ser maior que 0",
      ],
      cadastroPrefeituraRules: [
        (value) => !!value || "O cadastro prefeitura é obrigatório",
        (value) =>
          (value && value.length <= 30) ||
          "O cadastro prefeitura deve ter menos do que 30 caracteres",
      ],
      numeroControleIptuRules: [
        (value) => !!value || "O n° controle iptu é obrigatório",
        (value) => /^\d+$/.test(value) || 'O n° controle iptu não é válido',
        (value) =>
          (value && value.length <= 12) ||
          "O n° controle iptu deve ter menos do que 12 caracteres",
      ],
      proprietarioRules: [
        (value) => !!value || "O proprietário é obrigatório",
      ],
      faseRules: [
        (value) => !!value || "A fase é obrigatória",
        (value) =>
          (value && value.length <= 20) ||
          "A fase deve ter menos do que 20 caracteres",
      ],
      codigoContabilRules: [
        (value) => !!value || "O código contábil é obrigatório",
        (value) => /^\d+$/.test(value) || 'O código contábil não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O código contábil deve ter menos do que 10 caracteres",
      ],
      caucionadoRules: [
        (value) => !!value || "Caucionado é obrigatório",
      ],
      confrontacoesRules: [
        (value) => !!value || "A confrontação é obrigatória",
        (value) =>
          (value && value.length <= 1000) ||
          "A confrontação deve ter menos do que 1000 caracteres",
      ],
    };
  },

  computed: {
    ImagemCapaCaminhoCompleto: function (dados) {
      return baseURL + dados.imagem_capa_caminho;
    },
    isAnyOptionDisabled() {
      return this.array_situacoes?.some(option => {
        if(!!option && this.dados.cod_situacao == option.cod_situacao && !!option.disabled){
          return true
        }
        else{
          return false
        }
      })
    }
  },

  watch: {
    search(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch(query);
      }
    },
  },

  methods: {
     async CadastraAtualizaImovel() {
      const lb_valido = this.$refs.form.validate();
      if(lb_valido) {
        // this.dados.cod_situacao = this.dados.imovel_situacao.cod_situacao
        // this.dados.cod_imoveltipo = this.dados.imovel_tipo.cod_imoveltipo

        let ls_Rel = null;
        var lo_params = {...JSON.parse(JSON.stringify(this.dados))};
        console.log('______________', lo_params)
        if (this.acao == "I") {
          ls_Rel = await API.post(`/imovel`, lo_params);
          this.dados.cod_imovel = ls_Rel.data.result.data.cod_imovel;
        }
        else if (this.acao == "E") {
          ls_Rel = await API.put(`imovel/${this.dados.cod_imovel}`, lo_params );
        }
        if (ls_Rel.status == 200 ) {
          this.acao = "C";
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Rel.data.result.result;
          this.store_site.alert = true;
        }
        else {
          this.store_site.alert_cor = "#FF0000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Rel.data.result;
          this.store_site.alert = true;
        }
        this.$emit('dadosAtualizados', this.dados.cod_imovel); // Verificar com o fernando como aplicar isso
      }
    },

    // Busca o endereço pelo CEP
    async buscaEndereco(val) {
      if (val && val.length == 9) {
        const ls_Rel_Cep = await axios.get(`https://viacep.com.br/ws/${val}/json/`);
        if (!ls_Rel_Cep.data.erro) {
          this.array_cep = ls_Rel_Cep.data;
          this.dados.logradouro = this.array_cep.logradouro;
          this.dados.bairro = this.array_cep.bairro;

          const ls_Rel_Municipios = await API.get(`municipio`, {params: { cod_ibge: this.array_cep.ibge }});

         if (ls_Rel_Municipios.data.result.length > 0) {
            let resultado =  [...ls_Rel_Municipios.data.result]
            this.arrayMunicipios = [{codigo: resultado[0].codigo, nome_exibicao: resultado[0].nome + ' - ' + resultado[0].uf}]
            // this.arrayMunicipios.map(el => el.nome = el.nome + ' - ' + el.uf)
            console.log(this.arrayMunicipios);
            this.dados.cod_municipio = this.arrayMunicipios[0].codigo         
          }
        }
      }
    },

     querySearch(query){
      let p_params = { params: { filtro: query } }
      this.loading = true
      this.busca_timeout(p_params);
      this.loading = false
    },

     busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
      const ls_Rel_Municipios = await API.get(`municipio`, val);
      if (Array.isArray(ls_Rel_Municipios.data.result)) {
        ls_Rel_Municipios.data.result.map(el => el.nome_exibicao = `${el.nome} - ${el.uf}`)
        this.arrayMunicipios = ls_Rel_Municipios.data.result;
      }
      }, 600);
    },

    async getTipoImovel(){
      const la_TipoImovel = await this.store_Empreendimento.getTipoImovel({ cod_empreendimento: this.$route.params.cod_empreendimento });
      this.array_tipo_imovel = la_TipoImovel;
    },

    async getSituacoes(){
      const la_Situacoes = await this.store_Empreendimento.getSituacoes({ cod_empreendimento: this.$route.params.cod_empreendimento, });
      this.array_situacoes = la_Situacoes.map(option => {
        // Verifica se a opção é do tipo 'Mapa de Venda' e se o status é permitido
        if (option.situacao_tipo === 'Mapa de Venda' && ["INDISPONIVEL", "RESERVADO", "DISPONIVEL", "PRE RESERVADO"].includes(option.situacao_sistema)) {
          return {
            ...option,
            disabled: false
          };
        } else if (option.situacao_tipo === 'Mapa de Venda') {
          // Retorna as opções do tipo 'Mapa de Venda' que não estão na lista permitida
          return {
            ...option,
            disabled: true
          };
        }
      });
    },

    // async getQuadras(){
    //   const la_Quadras = await this.store_Empreendimento.getQuadras({ cod_empreendimento: this.$route.params.cod_empreendimento });
    //   this.array_quadras = la_Quadras;
    // },

    // async getProprietarios(){
    //   const la_Proprietarios = await this.store_Empreendimento.getProprietarios({ cod_empreendimento: this.$route.params.cod_empreendimento });
    //   this.array_proprietarios = la_Proprietarios;
    // },

    calcularPreco(){
      if(!!this.dados.area_metro && !!this.dados.preco_metro){
        this.dados.preco_total = this.dados.area_metro * this.dados.preco_metro
      }
    },

    calcularPrecoMetro(){
      if(this.dados.area_metro){
        this.dados.preco_metro =
          this.dados.preco_total / this.dados.area_metro
      }
    },

    Editar() {
      if (this.acao = "E") {
        this.imovel_edicaoAux = {
          ...this.dados,
        };
      }
    },

    cancelar() {
      if (this.acao == "I") {
        this.imovel_edicaoAux = null;
      } else if (this.acao = "E") {
        this.dados = {
          ...this.imovel_edicaoAux,
        };
      }
      this.acao = "C"
    },
  },

  async mounted() {
    await this.getSituacoes();
    await this.getTipoImovel();

    // Carrega os dados recebidos por parâmetro
    if(!!this.array_unidades_detalhes){
      this.dados = {...this.array_unidades_detalhes}
    }
    else{
      this.dados = {}
    }

    // Alimenta o array de municípios com o município do imóvel caso tenha um
    if(this.dados.imovel_municipio){
      this.arrayMunicipios = [{ codigo: this.dados.imovel_municipio.codigo, nome_exibicao: this.dados.imovel_municipio.nome + ' - ' + this.dados.imovel_municipio.uf }]
    }

    console.log('this.dados', this.dados)
  },
};
</script>

<style scoped>
#ImovelCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media (max-width: 599px) {
  #ImovelCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#ImovelCadastro::-webkit-scrollbar {
  width: 5px;
}

#ImovelCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#ImovelCadastro::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#ImovelCadastro::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #ImovelCadastro::-webkit-scrollbar {
    width: initial;
  }

  #ImovelCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #ImovelCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #ImovelCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  color: #ff0000;
}

.custom-label  {
  color: #272727; 
  
}
</style>
